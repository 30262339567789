import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const ExploreSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  gap: 16px;
  overflow: auto;
  padding: 16px 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 8px;
  }
`;

export const ExploreTile = styled.div`
  height: 42px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.background.bg1};
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  border-radius: 12px;
`;
export const ExploreTileTitle = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;
