import DOMPurify from 'isomorphic-dompurify';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import usePlusMembershipFeatures from '../../../../hooks/localeConstants/usePlusMembershipFeatures';
import { useAppSelector } from '../../../../redux/hooks';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { BackgroundIcon } from '../../../Icons/Icons';
import Button from '../../../NewButton/Button';
import {
  ExtraDiscountJoinNowButton,
  GetExtraDiscountLeft,
  GetExtraDiscountLeftIllustration,
  GetExtraDiscountRight,
  GetExtraDiscountRightBodyList,
  GetExtraDiscountRightBodyListItem,
  GetExtraDiscountRightTitle,
  GetExtraDiscountTitle,
  GetExtraDiscountWrapper,
} from '../LandingPageStyle';

function GetExtraDiscountSection() {
  const user = useAppSelector((state) => state.user);
  const router = useRouter();

  const { features } = usePlusMembershipFeatures();

  const { messages } = useLocale();
  const {
    plus_get_extra_discounts_with_driffle_msg,
    plus_join_now_msg,
    plus_perks_of_driffle_plus_msg,
  } = messages || {};

  return (
    <GetExtraDiscountWrapper>
      <GetExtraDiscountLeft>
        <GetExtraDiscountLeftIllustration>
          <BackgroundIcon
            url="/icons/plus-illustration.svg"
            height="276px"
            width="276px"
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        </GetExtraDiscountLeftIllustration>
        <GetExtraDiscountTitle
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              plus_get_extra_discounts_with_driffle_msg?.replace(
                '[--PLUSLOGO--]',
                `
                  <img
                    src="/logo/plus-logo.svg"
                    height="40px"
                    width="80px"
                    style="margin: 0 8px 0 0px; display: inline;" 
                    alt="Plus Logo"
                  />
                `
              ) ?? ''
            ),
          }}
        ></GetExtraDiscountTitle>
        {user.subscription === 'active' ||
        router.pathname === '/plus' ? null : (
          <Link href={'/plus'} prefetch={false} passHref>
            <a>
              <ExtraDiscountJoinNowButton>
                <Button
                  onClick={() => {
                    saveEventV3({
                      category: 'plus_banner',
                      label: 'join_now',
                      action: 'click',
                      properties: '',
                      value: [],
                      from: router,
                    });
                  }}
                >
                  {plus_join_now_msg}
                </Button>
              </ExtraDiscountJoinNowButton>
            </a>
          </Link>
        )}
      </GetExtraDiscountLeft>
      <GetExtraDiscountRight>
        <GetExtraDiscountRightTitle>
          {plus_perks_of_driffle_plus_msg?.replace(
            '[--DRIFFLE_PLUS--]',
            'Driffle Plus'
          )}
        </GetExtraDiscountRightTitle>
        <GetExtraDiscountRightBodyList>
          {features.map((feature, index) => (
            <GetExtraDiscountRightBodyListItem key={index}>
              <div>
                <BackgroundIcon
                  url={`/icons/plus-white-tick.svg`}
                  height="20px"
                  width="20px"
                  margin="0"
                />
              </div>
              <p>{feature}</p>
            </GetExtraDiscountRightBodyListItem>
          ))}
        </GetExtraDiscountRightBodyList>
      </GetExtraDiscountRight>
    </GetExtraDiscountWrapper>
  );
}

export default memo(GetExtraDiscountSection);
