import { Skeleton } from '@mui/material';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { OriginalImageContainer, SkeletonContainer } from '../skeletons.style';

function ProductCardSkeleton() {
  const theme = useTheme();
  return (
    <SkeletonContainer>
      <OriginalImageContainer>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          animation="wave"
          sx={{
            borderRadius: '8px',
            paddingTop: '140%',
            background: theme.palette.background.bg3,
          }}
        />
      </OriginalImageContainer>
      <Skeleton
        variant="text"
        height="20px"
        width="100%"
        animation="wave"
        sx={{
          borderRadius: '8px',
          background: theme.palette.background.bg3,
          marginTop: '-8px',
        }}
      />
      <Skeleton
        variant="text"
        height="20px"
        width="60%"
        animation="wave"
        sx={{
          borderRadius: '8px',
          background: theme.palette.background.bg3,
          marginTop: '-16px',
        }}
      />
    </SkeletonContainer>
  );
}

export default memo(ProductCardSkeleton);
