import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';
import { saveEventV3 } from '../../../utils/eventTracking';
import { triggerBannerClick } from '../../../utils/gtm';
import { setItmCookie } from '../../../utils/itmTracking';
import { HeroGridTile3 } from '../HeroGrid.styles';
import { TileProps } from './HeroGridTile1';
import { TileImage } from './TileImage';
export const HeroGridTile3Comp = ({ data }: TileProps) => {
  const router = useRouter();
  const optimizedUrl = useMemo(() => {
    return data?.imageUrl
      ?.split('media-gallery')
      ?.join('fit-in/780x3400/media-gallery');
  }, [data?.imageUrl]);
  return (
    <HeroGridTile3
      onClick={() => {
        if (data) {
          triggerBannerClick({
            category: 'banner',
            label: 'tile-3',
            properties: 'grid_banner',
            value: data.title,
            position: 'tile-3',
          });
          saveEventV3({
            category: 'banner',
            action: 'click',
            label: 'tile-3',
            properties: 'grid_banner',
            value: [data.title],
            from: router,
          });
          setItmCookie({
            source: 'banner',
            medium: 'banner-tile-3',
            campaign: 'homepage-banner',
            content: data?.title || '',
          });
        }
      }}
    >
      <TileImage
        image={optimizedUrl || ''}
        link={data?.link || ''}
        title={data?.title || 'tile'}
      />
    </HeroGridTile3>
  );
};

export default memo(HeroGridTile3Comp);
