import dynamic from 'next/dynamic';
import { useContext } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { useAppSelector } from '../../../../redux/hooks';
import { GWMobileWrapper, GWWebWrapper } from '../../../../styles/window.style';
import DrifflePlusSellCard from '../../../Membership/LandingPage/sections/DrifflePlusSellCard';
import GetExtraDiscountSection from '../../../Membership/LandingPage/sections/GetExtraDiscountSection';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import { SectionWrapper } from '../../HomePageV1Style';

import ProductsCardRowSkeleton from '../skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
const ProductCardsCluster = dynamic(
  () => import('../ProductCardsCluster/ProductCardsCluster'),
  {
    loading: () => <ProductsCardRowSkeleton />,
  }
);

function Section1() {
  const { bestSellersCollection } = useContext(HomePageV1Context);
  const { messages } = useLocale();
  const { best_selling_games_msg } = messages || {};

  const user = useAppSelector((state) => state.user);

  return (
    <>
      <SectionWrapper surface="light">
        {!bestSellersCollection || !bestSellersCollection.length ? (
          <ProductsCardRowSkeleton />
        ) : (
          <ProductCardsCluster
            title={best_selling_games_msg ?? ''}
            products={bestSellersCollection}
            titleName="best_selling_games"
            collectionLink="/store/collection/best-selling-games"
            hideOOSProducts={true}
          />
        )}
      </SectionWrapper>
      {user.subscription === 'no' ? null : (
        <>
          <GWWebWrapper>
            <GetExtraDiscountSection />
          </GWWebWrapper>
          <GWMobileWrapper>
            <DrifflePlusSellCard />
          </GWMobileWrapper>
        </>
      )}

      {/* <SectionWrapper surface="dark">
        {!bestSellersCollection || !bestSellersCollection.length ? (
          <SmallCardSkeletonRow />
        ) : (
          // <GiftCardSection />
          <PlatformsSection />
        )}
      </SectionWrapper> */}
    </>
  );
}

export default Section1;
