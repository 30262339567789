// import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';
import { BannerImageI } from '../../Interface/HomepageCollectionsInterface';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { triggerBannerClick } from '../../utils/gtm';
import { setItmCookie } from '../../utils/itmTracking';
import { WE_USER_EVENT_BANNER_CLICK } from '../../utils/we';
import Button from '../NewButton/Button';
import {
  ActionSection,
  BottomOverlay,
  CarouselItemContainer,
} from './HeroGridCarousel.styles';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

const HeroGridCarouselItem = ({
  data,
  index,
}: {
  data: BannerImageI;
  index: number;
}) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  const optimizedUrl = useMemo(() => {
    return data.imageUrl
      ?.split('media-gallery')
      ?.join('fit-in/1260x2880/media-gallery');
  }, [data.imageUrl]);

  return (
    <CarouselItemContainer
      onClick={() => {
        WE_USER_EVENT_BANNER_CLICK(
          {
            bannerName: data.title,
            bannerUrl: data.link,
          },
          cleverTap
        );
        triggerBannerClick({
          category: 'banner',
          label: 'carousel',
          properties: 'main_banner',
          value: data.title,
          position: String(index + 1),
        });
        saveEventV3({
          category: 'banner',
          action: 'click',
          label: 'carousel',
          properties: 'main_banner',
          value: [data.title],
          from: router,
          jsonData: {
            clickIndex: index,
          },
        });
        setItmCookie({
          source: 'banner',
          medium: `banner-carousel-${index}`,
          content: data.title,
          campaign: 'homepage_banner',
        });
      }}
    >
      <Link href={data.link} prefetch={false} passHref>
        <a>
          <Image
            src={optimizedUrl}
            id="carousel-banner-image"
            alt={data?.title}
            layout="fill"
            objectFit="cover"
            // quality={50}
            unoptimized={true}
            priority={index === 0 || index === 1 || index === 2}
          />

          <ActionSection>
            <p>{data.title}</p>
            {/* <Link href={data.link} prefetch={false} passHref> */}
            <Button
              height="48px"
              width="fit-content"
              style={{
                minWidth: '148px',
              }}
              buttonTheme="white"
            >
              {JSON.parse(
                JSON.stringify((data?.metadata || null)?.btnText || 'Buy Now')
              )}
            </Button>
            {/* </Link> */}
          </ActionSection>
          <BottomOverlay />
        </a>
      </Link>
    </CarouselItemContainer>
  );
};

export default memo(HeroGridCarouselItem);
