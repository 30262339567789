import { Skeleton } from '@mui/material';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { OriginalImageContainer, SkeletonContainer } from '../skeletons.style';

function SmallCardSkeleton() {
  const theme = useTheme();
  return (
    <SkeletonContainer>
      <OriginalImageContainer>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          animation="wave"
          sx={{
            borderRadius: '8px',
            paddingTop: '60%',
            background: theme.palette.background.bg3,
          }}
        />
      </OriginalImageContainer>
    </SkeletonContainer>
  );
}

export default memo(SmallCardSkeleton);
