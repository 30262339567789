import { Skeleton } from '@mui/material';
import { useTheme } from 'styled-components';
import {
  ProductSectionWrapper,
  SectionTitleContainer,
  SmallCardSectionContainer,
  SmallCardSectionRowContainer,
} from '../../../HomePageV1Style';
import SmallCardSkeleton from './SmallCardSkeleton';

function SmallCardSkeletonRow() {
  const theme = useTheme();
  return (
    <ProductSectionWrapper>
      <SectionTitleContainer>
        <Skeleton
          variant="text"
          height={'24px'}
          width={'300px'}
          style={{ background: theme.mode === 'dark' ? '#2b2b2b' : '#e0e0e0' }}
        />
      </SectionTitleContainer>
      <SmallCardSectionContainer>
        <SmallCardSectionRowContainer>
          {Array.from({ length: 6 }).map((_, index) => (
            <SmallCardSkeleton key={index} />
          ))}
        </SmallCardSectionRowContainer>
      </SmallCardSectionContainer>
    </ProductSectionWrapper>
  );
}

export default SmallCardSkeletonRow;
