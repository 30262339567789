import styled from 'styled-components';

export const OriginalImageContainer = styled.div`
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  height: auto;
  width: 100%;
  position: relative;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;
`;
