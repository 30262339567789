import { useCallback, useMemo, useState } from 'react';
import { RecombeeRecommendationI } from '../../Interface/RecombeeRecommendationsInterface';
import { useAppSelector } from '../../redux/hooks';
import { getUserRecommendations } from '../../utils/recombee';

interface Props {
  scenario: string;
  fromPage: string;
  limit?: number;
}

function useFetchRecommendations({ scenario, fromPage, limit }: Props) {
  const [recombeeRecommendations, setRecombeeRecommendations] = useState<
    RecombeeRecommendationI | null | 'loading'
  >('loading');
  const { locationDetails } = useAppSelector((state) => state.global);

  const getRecommendation = useCallback(async () => {
    try {
      const results = await getUserRecommendations(
        scenario,
        fromPage,
        limit ?? 60,
        locationDetails?.country_code || 'OTH'
      );
      if (results) {
        setRecombeeRecommendations(results);
      } else {
        setRecombeeRecommendations(null);
      }
    } catch (error) {
      console.error('Error in fetching recommendations', error);
      setRecombeeRecommendations(null);
    }
  }, [fromPage, limit, locationDetails?.country_code, scenario]);

  const memod = useMemo(() => {
    return { getRecommendation, recombeeRecommendations };
  }, [getRecommendation, recombeeRecommendations]);

  return memod;
}

export default useFetchRecommendations;
