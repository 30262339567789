import { useContext, useEffect } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { useTrendingProducts } from '../../../../contexts/TrendingProductsContext';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import { SectionWrapper } from '../../HomePageV1Style';

const PlatformsSection = dynamic(
  () => import('../PlatformsSection/PlatformsSection'),
  {
    loading: () => <SmallCardSkeletonRow />,
  }
);

const ProductCardsCluster = dynamic(
  () => import('../ProductCardsCluster/ProductCardsCluster'),
  {
    loading: () => <ProductsCardRowSkeleton />,
  }
);

import dynamic from 'next/dynamic';
import ProductsCardRowSkeleton from '../skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import SmallCardSkeletonRow from '../skeletons/SmallCardSkeletonRow/SmallCardSkeletonRow';

function Section0() {
  const { recommendationsForYou } = useContext(HomePageV1Context);
  const {
    trendingProducts,
    fetchTrendingProducts,
    loading: trendingProductsLoading,
  } = useTrendingProducts();
  const { messages } = useLocale();
  const { recommended_msg } = messages || {};

  useEffect(() => {
    if (!trendingProducts && recommendationsForYou === null) {
      fetchTrendingProducts();
    }
  }, [fetchTrendingProducts, trendingProducts, recommendationsForYou]);

  return (
    <>
      <SectionWrapper surface="light">
        {recommendationsForYou === 'loading' ? (
          <ProductsCardRowSkeleton />
        ) : recommendationsForYou === null ? (
          trendingProductsLoading || !trendingProducts ? (
            <ProductsCardRowSkeleton />
          ) : (
            <ProductCardsCluster
              title={recommended_msg ?? ''}
              products={trendingProducts}
              titleName="recommended_for_you"
              // collectionLink="/store/collection/recommended-for-you"
            />
          )
        ) : (
          <ProductCardsCluster
            title={recommended_msg ?? ''}
            products={recommendationsForYou.list}
            recommendationId={recommendationsForYou.id}
            titleName="recommended_for_you"
            collectionLink="/store/collection/recommended-for-you"
          />
        )}
      </SectionWrapper>
      <SectionWrapper surface="dark">
        {/* {!recommendationsForYou ||
        recommendationsForYou === 'loading' ||
        !recommendationsForYou?.list?.length ? (
          <SmallCardSkeletonRow />
        ) : ( */}
        <PlatformsSection />
        {/* )} */}
      </SectionWrapper>
    </>
  );
}

export default Section0;
