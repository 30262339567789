import { useRouter } from 'next/router';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppDispatch } from '../../redux/hooks';
import { openMobileSearchDialog } from '../../redux/modalSlice';
import {
  SearchInputBox,
  SearchWrapper,
} from '../../styles/pageStyles/homeStyle';
import { saveEventV3 } from '../../utils/eventTracking';
import { MaskIcon } from '../Icons/Icons';
import DesktopPage from './components/desktop/DesktopPage';

function HomePageV1() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { messages } = useLocale();
  const { search_games_gift_msg } = messages || {};

  return (
    <div>
      <SearchWrapper
        onClick={() => {
          saveEventV3({
            category: 'header',
            action: 'click',
            label: 'search',
            properties: '',
            value: [],
            from: router,
          });
          dispatch(openMobileSearchDialog());
        }}
      >
        <SearchInputBox>
          <MaskIcon
            url="/icons/search-24.svg"
            height="24px"
            width="24px"
            selected={true}
            margin="0"
          />
          <div>{search_games_gift_msg}</div>
        </SearchInputBox>
      </SearchWrapper>
      <DesktopPage />
    </div>
  );
}

export default HomePageV1;
