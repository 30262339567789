import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const MobileBodyContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  display: none;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: block;
    padding: 0 0 24px;
  }
`;

export const TabletBodyContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  display: flex;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const WebBodyContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  display: block;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const InvisibleBox = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  height: 56px;
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 24px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 4px;
  }
`;

export const SearchWrapper = styled.div`
  display: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    position: relative;
    width: 100%;
    background: ${COLORS.headerBg};
    display: flex;
  }
`;

export const SearchInputBox = styled.div`
  background: ${COLORS.white};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 50px;
  width: 100%;
  padding: 0 16px;
  margin: 0 16px 12px;
  height: 48px;
  gap: 16px;
  div {
    font: 1rem/1.25rem Onest-Medium;
    color: ${COLORS.gray3};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const NewSection1Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px 0 0;
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  gap: 8px;
  @media (max-width: ${WIDTH.laptopMid}) {
    flex-direction: column;
    padding: 0;
    // margin-bottom: 24px;
    gap: 24px;
  }
`;

export const ClusterItemWrapper = styled.div`
  max-width: 342px;
  width: 100%;
  height: 100%;
  @media (max-width: ${WIDTH.laptopMid}) {
    max-width: 100%;
    padding: 0 16px;
  }
`;
