import { Skeleton } from '@mui/material';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import {
  CardWrapper,
  ProductSectionContainer,
  ProductSectionWrapper,
  ProductsSectionRowContainer,
  SectionTitleContainer,
} from '../../../HomePageV1Style';
import ProductCardSkeleton from './ProductCardSkeleton';

function ProductsCardRowSkeleton() {
  const theme = useTheme();
  return (
    <ProductSectionWrapper>
      <SectionTitleContainer>
        <Skeleton
          variant="text"
          height={'24px'}
          width={'300px'}
          style={{ background: theme.mode === 'dark' ? '#2b2b2b' : '#e0e0e0' }}
        />
      </SectionTitleContainer>
      <ProductSectionContainer>
        <ProductsSectionRowContainer>
          {Array.from({ length: 6 }).map((_, index) => (
            <CardWrapper key={index}>
              <ProductCardSkeleton />
            </CardWrapper>
          ))}
        </ProductsSectionRowContainer>
      </ProductSectionContainer>
    </ProductSectionWrapper>
  );
}

export default memo(ProductsCardRowSkeleton);
