import {
  BannerImageAPIInterface,
  TileImageAPIInterface,
} from '../../Interface/HomepageCollectionsInterface';
import HeroGridCarouselComp from '../HeroGridCarousel/HeroGridCarousel';
import HeroGridTile1Comp from './components/HeroGridTile1';
import HeroGridTile2Comp from './components/HeroGridTile2';
import HeroGridTile3Comp from './components/HeroGridTile3';
import HeroGridTile4Comp from './components/HeroGridTile4';
import HeroGridTile5Comp from './components/HeroGridTile5';
import {
  HeroGridCarousel,
  HeroGridWrapper,
  ShowDesktopOnly,
  ShowMobileOnly,
} from './HeroGrid.styles';

interface Props {
  bannerImages: BannerImageAPIInterface | null;
  tileImages: TileImageAPIInterface | null;
}

const HeroGrid = ({ bannerImages, tileImages }: Props) => {
  return (
    <>
      {/**
       * for mobile viewport
       */}

      <ShowMobileOnly>
        <HeroGridWrapper>
          <HeroGridCarousel>
            <HeroGridCarouselComp bannerImages={bannerImages} />
          </HeroGridCarousel>
          <HeroGridTile1Comp data={tileImages?.mt1 || null} />
          <HeroGridTile2Comp data={tileImages?.mt2 || null} />
          <HeroGridTile3Comp data={tileImages?.mt3 || null} />
          <HeroGridTile4Comp data={tileImages?.mt4 || null} />
          <HeroGridTile5Comp data={tileImages?.mt5 || null} />
        </HeroGridWrapper>
      </ShowMobileOnly>

      {/**
       * for desktop and tablet viewport
       */}

      <ShowDesktopOnly>
        <HeroGridWrapper>
          <HeroGridCarousel>
            <HeroGridCarouselComp bannerImages={bannerImages} />
          </HeroGridCarousel>
          <HeroGridTile1Comp data={tileImages?.dt1 || null} />
          <HeroGridTile2Comp data={tileImages?.dt2 || null} />
          <HeroGridTile3Comp data={tileImages?.dt3 || null} />
          <HeroGridTile4Comp data={tileImages?.dt4 || null} />
          <HeroGridTile5Comp data={tileImages?.dt5 || null} />
        </HeroGridWrapper>
      </ShowDesktopOnly>
    </>
  );
};

export default HeroGrid;
