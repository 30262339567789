import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';
import { BannerImageI } from '../../../Interface/HomepageCollectionsInterface';
import { saveEventV3 } from '../../../utils/eventTracking';
import { triggerBannerClick } from '../../../utils/gtm';
import { setItmCookie } from '../../../utils/itmTracking';
import { HeroGridTile1 } from '../HeroGrid.styles';
import { TileImage } from './TileImage';

export interface TileProps {
  data: BannerImageI | null;
}

const HeroGridTile1Comp = ({ data }: TileProps) => {
  const router = useRouter();

  const optimizedUrl = useMemo(() => {
    return data?.imageUrl
      ?.split('media-gallery')
      ?.join('fit-in/816x1536/media-gallery');
  }, [data?.imageUrl]);

  return (
    <HeroGridTile1
      onClick={() => {
        if (data) {
          triggerBannerClick({
            category: 'banner',
            label: 'tile-1',
            properties: 'grid_banner',
            value: data.title,
            position: 'tile-1',
          });
          saveEventV3({
            category: 'banner',
            action: 'click',
            label: 'tile-1',
            properties: 'grid_banner',
            value: [data.title],
            from: router,
          });
          setItmCookie({
            source: 'banner',
            medium: 'banner-tile-1',
            campaign: 'homepage-banner',
            content: data?.title || '',
          });
        }
      }}
    >
      <TileImage
        image={optimizedUrl || ''}
        link={data?.link || ''}
        title={data?.title || 'tile'}
      />
    </HeroGridTile1>
  );
};

export default memo(HeroGridTile1Comp);
