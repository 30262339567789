import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const HeroGridCarouselWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.background.bg2};
  max-height: 420px;
  @media (max-width: ${WIDTH.desktopMin}) {
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 100%;
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-height: 100%;
    padding: 0;
  }
`;

export const HeroGridCarouselContainer = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background.bg2};
  max-height: 420px;
  height: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-height: 178px;
  }
`;

export const CarouselItemContainer = styled.div`
  height: 420px;
  position: relative;
  border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  background: ${(props) => props.theme.palette.background.bg2};
  width: 100%;

  > a > span {
    #carousel-banner-image {
      border-radius: ${(props) => props.theme.palette.borderRadius.b4};
      cursor: pointer;
    }
  }

  @media (max-width: ${WIDTH.laptopMid}) {
    width: 100%;
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    max-height: 178px;
  }
`;

export const NextArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 16px;
  cusror: pointer;
  left: auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 16px 16px 0;

  height: 100%;

  @media (max-width: ${WIDTH.tabletMax}) {
    background: none;
    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 178px;
    width: 12px;
    > span {
      display: none;
    }
  }
`;

export const PreviousArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 16px;
  right: auto;
  cusror: pointer;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  border-radius: 0 16px 16px 0;

  height: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 64px;
    height: 280px;
    background: none;

    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 12px;
    height: 178px;
    > span {
      display: none;
    }
  }
`;

export const CustomDotWrapper = styled.ul`
  background: #00000099;
  width: fit-content;
  border-radius: 24px;
  padding: 0px 2px;

  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 0px;
  }
`;

export const CustomDotInk = styled.li<{ className: string }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  opacity: ${(props) => (props.className === 'slick-active' ? '0.8' : '0.25')};
  background: ${COLORS.white};

  @media (max-width: ${WIDTH.mobileMax}) {
    height: 8px;
    width: 8px;
  }
`;

export const ActionSection = styled.div`
  height: 216px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  padding-top: 74px;
  padding-left: 118px;
  padding-bottom: 38px;
  bottom: 0;
  & > p {
    color: #fff;
    font-family: Onest-SemiBold;
    font-size: 32px;
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    display: none;
  }
`;

export const BottomOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom:0;
  height:220px;
  z-index:1
  opacity:0.8;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: ${WIDTH.laptopMid}) {
    display: none;
  }
`;
