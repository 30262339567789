import styled, { css } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const HeroGridWrapper = styled.div`
  max-width: 1479px;
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  grid-auto-rows: minmax(130px, auto);
  margin: 32px auto 0 auto;
  padding: 0 16px;

  @media (max-width: 1720px) {
    max-width: 1440px;
  }

  @media (max-width: ${WIDTH.laptopMid}) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(130px, auto);
    margin: 16px auto 0 auto;
  }

  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 auto 8px 0;
  }

  @media (max-width: ${WIDTH.tabletMid}) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(80px, auto);
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 8px;
  }
`;

export const MobileSqaureTile = css`
  display: grid;
  grid-auto-rows: minmax(120px, auto);
  @media (max-width: 600px) {
    min-height: 80px;
    max-width: 300px;
    max-height: 120px;
    aspect-ratio: 1 / 1;
  }
`;

export const GridTile = styled.div`
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    border-radius: 8px;
  }
`;

export const HeroGridCarousel = styled(GridTile)`
  grid-column: 1 / 3;
  grid-row: 1/4;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-row: 1;
  }
  @media (max-width: ${WIDTH.tabletMid}) {
    grid-row: 1;
    grid-column: 1/5;
  }
`;

export const HeroGridTile1 = styled(GridTile)`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-row: 2/4;
    grid-column: 1;
    max-height: 272px;
  }

  @media (max-width: ${WIDTH.tabletMid}) {
    ${MobileSqaureTile}
    grid-column: 1;
    grid-row: 2;
    max-height: 300px;
  }
`;
export const HeroGridTile2 = styled(GridTile)`
  grid-column: 3;
  grid-row: 3;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-column: 2;
    grid-row: 2;
  }
  @media (max-width: ${WIDTH.tabletMid}) {
    ${MobileSqaureTile}
    grid-column: 2;
    grid-row: 2;
  }
`;

export const HeroGridTile3 = styled(GridTile)`
  grid-column: 3;
  grid-row: 4;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-column: 2;
    grid-row: 3;
  }
  @media (max-width: ${WIDTH.tabletMid}) {
    ${MobileSqaureTile}
    grid-column: 3;
    grid-row: 2;
  }
`;
export const HeroGridTile4 = styled(GridTile)`
  grid-column: 2;
  grid-row: 4;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-column: 2;
    grid-row: 4;
  }
  @media (max-width: ${WIDTH.tabletMid}) {
    ${MobileSqaureTile}
    grid-column: 4;
    grid-row: 2;
  }
`;
export const HeroGridTile5 = styled(GridTile)`
  grid-column: 1;
  grid-row: 4;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-column: 1;
    grid-row: 4;
  }
  @media (max-width: ${WIDTH.tabletMid}) {
    display: none;
  }
`;

export const ShowMobileOnly = styled.div`
  display: none;
  @media (max-width: ${WIDTH.tabletMid}) {
    display: block;
  }
`;

export const ShowDesktopOnly = styled.div`
  display: block;
  @media (max-width: ${WIDTH.tabletMid}) {
    display: none;
  }
`;

export const HeroGridOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
`;
