import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENT_EXPLORE_MOBILE_CLICKED } from '../../utils/we';
import { ExploreMobileSectionWrapper } from '../MobileHomePageSection/MobileHomePageSectionStyle';
import {
  ExploreSectionWrapper,
  ExploreTile,
  ExploreTileTitle,
} from './MobileExploreSectionStyle';

const exploreData = [
  {
    title: 'Games',
    link: '/store?productType[]=game&page=1',
    icon: '/icons/explore/games.svg',
  },
  {
    title: 'Giftcards',
    link: '/store?page=1&productType[]=giftcard',
    icon: '/icons/explore/giftcards.svg',
  },
  // {
  //   title: 'Gamepoints',
  //   link: '',
  //   icon: '/icons/explore/gamepoints.svg',
  // },
  {
    title: 'Xbox',
    link: '/store?page=1&platform[]=Xbox+Live',
    icon: '/icons/explore/xbox-24.svg',
  },
  {
    title: 'PSN',
    link: '/store?platform[]=PSN&page=1',
    icon: '/icons/explore/playstation-24.svg',
  },
  {
    title: 'Steam',
    link: '/store?platform[]=Steam&page=1',
    icon: '/icons/explore/steam-24.svg',
  },
  {
    title: 'Nintendo',
    link: '/store?page=1&platform[]=Nintendo',
    icon: '/icons/explore/nintendo-24.svg',
  },
];

const MobileExploreSection = ({ title }: { title: string }) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  return (
    <ExploreMobileSectionWrapper style={{ margin: 0 }}>
      {/* <MobileSectionHeader style={{ margin: '0 16px 0' }}>
        {title}
      </MobileSectionHeader> */}
      <ExploreSectionWrapper>
        {exploreData.map((tile, index) => {
          return (
            <Link passHref href={tile.link} key={index}>
              <ExploreTile
                onClick={() => {
                  saveEventV3({
                    category: 'collection_card',
                    action: 'click',
                    label: 'explore_mobile',
                    properties: 'collection',
                    value: [tile.link],
                    from: router,
                  });
                  WE_USER_EVENT_EXPLORE_MOBILE_CLICKED(
                    {
                      link: tile.link,
                      name: tile.title,
                      pageUrl: window.location.href,
                    },
                    cleverTap
                  );
                }}
              >
                {/* <MaskIcon
                  url={tile.icon}
                  height="40px"
                  width="40px"
                  margin="0"
                  selected={true}
                  color={theme.palette.text.t1}
                /> */}
                <ExploreTileTitle>{tile.title}</ExploreTileTitle>
              </ExploreTile>
            </Link>
          );
        })}
      </ExploreSectionWrapper>
    </ExploreMobileSectionWrapper>
  );
};

export default memo(MobileExploreSection);
