import styled from 'styled-components';
import { ProductCardLocation } from '../../Interface/ProductCardInterface';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

interface Props {
  type: 'dark' | 'light';
}
export const MobileSectionContainer = styled.div<Props>`
  // background: ${(props) => props.theme.palette.background.bg2};
`;

export const MobileSectionHeader = styled.h2`
  font: 1.25rem/1.5rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  text-transform: capitalize;
  margin: 0;
`;

export const MobileSectionWrapper = styled.div`
  padding: 0 16px 16px;
  // margin: 0 0 16px;
  overflow: hidden;
  padding-bottom: 0;
  // background: ${(props) => props.theme.palette.background.bg2};
  > div > button {
    width: 100%;
  }
`;

export const ExploreMobileSectionWrapper = styled.div`
  display: none;
  margin: auto;
  overflow: hidden;
  margin-top: 12px;
  background: ${(props) => props.theme.palette.background.bg2};
  > div > button {
    margin: auto;
    width: 100%;
  }
  @media (min-width: ${WIDTH.laptopMin}) {
    display: none;
    padding: 0;
  }

  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
  }
`;

export const MobileCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  padding: 16px 0;
`;
export const SectionTry = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  padding: 16px 0;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 12px;
  padding: 16px;
`;
export const SkeletonContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  flex: 1;
`;

export const LoaderContainer = styled.div`
    height: 100%;
    display: flex
    align-items: center;
    justify-content: center;
`;
interface MobileProductCardWrapperI {
  fullWidth: boolean;
  location: string;
  page?: ProductCardLocation;
}
export const MobileProductCardWrapper = styled.div<MobileProductCardWrapperI>`
  display: flex;
  gap: 16px;
  position: relative;
  cursor: pointer;
  background: ${(props) =>
    props.page === 'pdp'
      ? props.theme.palette.background.bg4
      : props.page === 'topup'
      ? props.theme.palette.background.bg3
      : props.theme.palette.background.bg1};
  border: 1px solid
    ${(props) =>
      props.page === 'topup'
        ? props.theme.palette.border.b2
        : props.theme.palette.border.b1};
  border-radius: 8px;
  padding: ${(props) =>
    props.location === 'bestsellingCluster' ? '8px' : '12px'};

  width: 100%;

  @media (max-width: ${WIDTH.tabletMax}) {
    flex-grow: 1;
    max-width: ${(props) =>
      props.fullWidth ? '100%' : 'calc((100% - 16px) / 2)'};
    min-width: ${(props) =>
      props.fullWidth ? '100%' : 'calc((100% - 16px) / 2)'};
    :hover {
      background: ${(props) =>
        props.page === 'pdp'
          ? props.theme.palette.background.bg4
          : props.theme.palette.background.bg1};
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 100%;
    min-width: 100%;
    // max-height: 128px;
  }
`;

export const MPImageWrapper = styled.div`
  flex: 0 1 auto;
  position: relative;
  display: flex;
  min-width: 100px;
  min-height: 140px;
  gap: 16px;
  img {
    border-radius: 8px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    flex: 0 1 86px;
    position: relative;
    display: flex;
    min-width: 86px;
    min-height: 124px;
    max-height: 124px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    flex: 0 1 84px;
    position: relative;
    display: flex;
    min-width: 84px;
    min-height: 124px;
    max-height: 124px;
  }
`;

export const MPContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const MPContentTitle = styled.div`
  font: 0.875rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 28px;
  margin-bottom: 8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 0.875rem/1rem Onest-Medium;
  }
`;

export const MPProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const MPProductInfo = styled.div`
  font: 12px/12px Onest-Regular;
  color: ${(props) => props.theme.palette.text.t1};
  padding: 0px 0 4px;
  max-height: 24px;
  width: fit-content;
  border-radius: 6px;
  gap: 8px;
  align-items: center;
  > div {
    align-items: center;
  }
`;
export const MPPlatformName = styled.div`
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-family: Onest-SemiBold;
  color: ${(props) => (props.theme.mode === 'dark' ? '#48bdff' : '#359dff')};
  text-transform: uppercase;
  -webkit-box-orient: vertical;
`;

export const MPProductPriceStrikeOff = styled.div`
  text-decoration: line-through;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  margin-bottom: -8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 0.75rem/1rem Onest-Medium;
  }
`;

export const MPProductPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  font: 1.25rem/1.5rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 1rem/1.25rem Onest-SemiBold;
  }
`;

export const MPProductSoldOut = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font: 1rem/1.25rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.error.dark};
`;

export const AddToCartBtnWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  right: 12px;
  bottom: 12px;
  background: ${(props) => props.theme.palette.background.bg3};
  border-radius: 8px;
  :hover {
    background: ${(props) => props.theme.palette.background.bg3}cc;
  }
`;

export const MobileShowMoreBtn = styled.div`
  button {
    background: ${(props) => props.theme.palette.background.bg1};
    color: ${(props) => props.theme.palette.text.t1};
    box-shadow: ${(props) => props.theme.shadows.s2};
    border: 1px solid ${(props) => props.theme.palette.divider.d1};
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    & button {
      &:hover {
        background: ${(props) => props.theme.palette.background.bg1};
      }
    }
  }
`;

export const DiscountTagWrapper = styled.div`
  position: absolute;
  right: -10px;
  bottom: 5px;
  z-index: 10;
  > div {
    position: relative;
    > p {
      position: absolute;
      top: 8px;
      right: 10px;
      font-family: Onest-Bold;
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      color: ${COLORS.white};
    }
  }
`;

export const DiscountBox = styled.div`
  height: 24px;
  width: 48px;
  background: #da0e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font: 0.75rem/1rem Onest-SemiBold;
  color: #fff;

  @media (max-width: ${WIDTH.mobileMax}) {
    width: 40px;
    height: 20px;
  }
`;

export const PreOrderTag = styled.div`
  height: 22px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.warning.main};
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;
  width: fit-content;
  & > p {
    color: ${(props) => props.theme.palette.warning.main};
    font-size: 12px;
    line-height: 14px;
    font-family: Onest-SemiBold;
    white-space: no-wrap;
  }
`;

export const PreOrderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 4px;
  & > p {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.palette.text.t3};
  }
`;
